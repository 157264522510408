.blurb {
    text-align:left;
    font-size:1.5em
}
.icon-holder {
    padding:0px;
    margin:0px;
    font-size:4em;
    i {color:#666;}
}
iframe {
    border:0
}
.overlay{
    position:fixed;
    width:100%;
    height:100%;
    padding:3%;
    top: 0;
    left: 0;
    background: rgba(#000, 0.85);
    z-index:1021;
    vertical-align: middle;
    .helper {
        display: relative;
        height: 100%;
        width: 100%;
        vertical-align: middle;
        img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-width: 95%;
            margin: 0 auto;
            max-height: 95%;
        }
    }
    .close{
        width:40px;
        height:40px;
        position:absolute;
        right:20px;
        top:20px;
        overflow:hidden;
        border:none;
        background: url(/images/cross.png) no-repeat center center;
        text-indent:200%;
        outline:none;
        color:transparent;
    }
    .right-arrow {
        width:60px;
        height:63px;
        position:absolute;
        right:20px;
        top:50%;
        overflow:hidden;
        border:none;
        background: url(/images/arrow-right.png) no-repeat center center;
        text-indent:200%;
        outline:none;
        color:transparent;
        opacity: 0.5;
        z-index:1025;
    }
    .left-arrow {
        width:60px;
        height:63px;
        position:absolute;
        left:20px;
        top:50%;
        overflow:hidden;
        border:none;
        background: url(/images/arrow-left.png) no-repeat center center;
        text-indent:200%;
        outline:none;
        color:transparent;
        opacity: 0.5;
        z-index:1025;
    }
} 